var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-table","fluid":"","tag":"section"}},[_c('base-panel',{attrs:{"icon":"mdi-forum","title":_vm.$t('quizzes.page title')}},[_c('delete-dialog',{ref:"deleteConfirm",attrs:{"url":_vm.apiQuery,"title":_vm.$t('quizzes.delete')},on:{"itemDeleted":_vm.onDeleted},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_vm._v(" "+_vm._s(_vm.$t("quizzes.confirm delete", item)))]}},{key:"error",fn:function(error){return [(error.message)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(error.message))]):_vm._e()]}},{key:"confirmed",fn:function(item){return [(item.id)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_vm._v(_vm._s(_vm.$t("quizzes.deleted", item)))]):_vm._e()]}}])}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"group-by":"project_id","show-group-by":"","multi-sort":"","footer-props":{
        'items-per-page-options': [20, 50],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(_vm.meta)?_c('v-select',{attrs:{"items":_vm.forSelect(_vm.meta.projects),"item-text":"text","item-value":"id","label":_vm.$t('tickets.fields.project')},on:{"change":_vm.projectChanged},model:{value:(_vm.options.project_id),callback:function ($$v) {_vm.$set(_vm.options, "project_id", $$v)},expression:"options.project_id"}}):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-home-plus")]),_vm._v(" "+_vm._s(_vm.$t("quizzes.add"))+" ")],1)],1)]},proxy:true},{key:"group.header",fn:function(ref){
      var items = ref.items;
      var isOpen = ref.isOpen;
      var toggle = ref.toggle;
      var remove = ref.remove;
return [_c('th',{attrs:{"colspan":"2"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_vm._v(" "+_vm._s(items[0].project_name)+" ")],1),_c('th',[_c('v-icon',{on:{"click":remove}},[_vm._v(_vm._s("mdi-close")+" ")])],1)]}},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [(item.isNew)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(item.id))]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.project_id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_name)+" ")]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))]),_c('br'),(item.image_url)?_c('img',{staticClass:"project_image",attrs:{"src":item.image_url}}):_vm._e()]}},{key:"item.hosts",fn:function(ref){
      var item = ref.item;
return _vm._l((item.hosts),function(h,index){return _c('a',{key:'h-' + index,staticClass:"project_url",attrs:{"target":"_blank","href":'http://' + h}},[_vm._v(_vm._s(h))])})}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-pencil")]),(item.id)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }