<template>
  <v-container id="user-table" fluid tag="section">
    <base-panel icon="mdi-forum" :title="$t('quizzes.page title')">
      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('quizzes.delete')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item">
          {{ $t("quizzes.confirm delete", item) }}</template
        >

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{
            error.message
          }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0">{{
            $t("quizzes.deleted", item)
          }}</v-alert>
        </template>
      </delete-dialog>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        group-by="project_id"
        show-group-by
        multi-sort
        :footer-props="{
          'items-per-page-options': [20, 50],
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-select
              v-if="meta"
              v-model="options.project_id"
              :items="forSelect(meta.projects)"
              item-text="text"
              item-value="id"
              :label="$t('tickets.fields.project')"
              @change="projectChanged"
            >
            </v-select>
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="onAdd">
              <v-icon left>mdi-home-plus</v-icon>
              {{ $t("quizzes.add") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`group.header`]="{ items, isOpen, toggle, remove }">
          <th colspan="2">
            <v-icon @click="toggle"
              >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
            </v-icon>
            {{ items[0].project_name }}
          </th>
          <th>
            <v-icon @click="remove">{{ "mdi-close" }} </v-icon>
          </th>
        </template>

        <template v-slot:[`item.id`]="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:[`item.project_id`]="{ item }">
          {{ item.project_name }}
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <b>{{ item.name }}</b
          ><br />
          <img
            v-if="item.image_url"
            :src="item.image_url"
            class="project_image"
          />
        </template>

        <template v-slot:[`item.hosts`]="{ item }">
          <a
            v-for="(h, index) in item.hosts"
            :key="'h-' + index"
            class="project_url"
            target="_blank"
            :href="'http://' + h"
            >{{ h }}</a
          >
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
          <v-icon v-if="item.id" small @click="onDelete(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </base-panel>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import DeleteDialog from "@/components/crud/DeleteDialog";

export default {
  components: {
    DeleteDialog,
  },
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: "/quizzes/",
      headers: [
        { text: "ID", align: "start", value: "id", groupable: false },
        { text: this.$t("quizzes.fields.project"), value: "project_id" },
        {
          text: this.$t("quizzes.fields.name"),
          value: "name",
          groupable: false,
        },
        {
          text: this.$t("quizzes.actions"),
          value: "actions",
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  created() {
    this.options.sortBy = ["updated_at"];
    this.options.sortDesc = [true];
    this.options.project_id =
      this.$store.state.projectId != null ? this.$store.state.projectId : 0;
    this.loadMeta();
  },
  methods: {
    loadMeta(uri = "create/") {
      this.get(this.apiQuery + uri).then((data) => {
        this.meta = data;
        this.meta.projects[0] = this.$t("projects.select all");
        this.autoLoad = true;
        if (this.options.project_id) {
          this.reloadData();
        }
      });
    },
    forSelect(obj) {
      const data = [];
      for (const i in obj) {
        data.push({
          id: parseInt(i),
          text: obj[i],
        });
      }
      return data;
    },
    onEdit(item) {
      this.$router.push("/pages/quizzes/" + item.id);
    },
    projectChanged(project) {
      this.$store.commit("SET_PROJECT", project);
    },
    onAdd() {
      if (this.options.project_id > 0) {
        this.post(this.apiQuery, this.options)
          .then((res) => {
            this.onEdit(res);
          })
          .catch((error) => {
            this.alert(error.message || error.error);
          });
      } else {
        this.alert(this.$t("quizzes.select project"), "warning");
      }
    },
  },
};
</script>
